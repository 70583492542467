import {
  GLOBAL_SUCCESS_EVENT_NAME,
  I_SUCCESS_POST_MESSAGE_STRUCTURE,
  T_SEC_MATER_EVENT_TYPES
} from './EVENT_CONTANTS'

const securityMasterLoadWorker = new Worker(
  new URL('./SecurityMasterLoadWorker.ts', import.meta.url),
  {
    type: 'module'
  }
)

const initiateMessage: T_SEC_MATER_EVENT_TYPES = 'INITIATE'
securityMasterLoadWorker.postMessage(initiateMessage)

securityMasterLoadWorker.onmessage = onMessage

function onMessage(event: MessageEvent<I_SUCCESS_POST_MESSAGE_STRUCTURE>) {
  const { data } = event

  const { data: secData } = data
  const {
    MASTER_DATA,
    SCRIPT_ID_INDEX,
    SEARCH_STRING_CHUNK,
    DERIVATIVE_INDEX,
    ISIN_CODE_INDEX
  } = secData

  ;(window as any).__MASTER_DATA__ = MASTER_DATA
  ;(window as any).__SCRIPT_ID_INDEX__ = SCRIPT_ID_INDEX
  ;(window as any).__SEARCH_STRING_CHUNK__ = SEARCH_STRING_CHUNK
  ;(window as any).__DERIVATIVE_INDEX__ = DERIVATIVE_INDEX
  ;(window as any).__ISIN_CODE_INDEX__ = ISIN_CODE_INDEX
  ;(window as any).__SEC_MASTER_LOADING__ = 'DONE'

  dispatchEvent(new Event(GLOBAL_SUCCESS_EVENT_NAME))
  securityMasterLoadWorker.terminate()
}
